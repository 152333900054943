export const jantarGeneral1 = {
  "id": 1,
  "name": 'jantar general',
  "location": 1,
  "schema": {
    "style": {
      "backgroundColor": '#ffffff',
      "color": "#3a3a3a",
      "accentColor": "#3a3a3a",
    },
    "pages": [
      {
        "content": [
          {
            "id": 1,
            "type": "image",
            "image": '/jantar-logo2.png'
          },
          {
            "id": 2,
            "type": "text",
            "style": {
              "fontSize": '14px',
            },
            "label": `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Amet nulla facilisi morbi tempus. Congue quisque egestas diam in arcu cursus euismod:\n
            • Integer quis auctor elit sed
            • Integer quis auctor elit sed
            • Integer quis auctor elit sed
            • Integer quis auctor elit sed
            • Integer quis auctor elit sed
            • Integer quis auctor elit sed`
          },
          {
            "id": 4,
            "type": "email",
            "required": false,
            "label": "Twój adres mailowy:",

          },
          {
            "id": 6,
            "type": "comment",
            "required": true,
            "label": "Komentarz:",
          },
          {
            "id": 7,
            "type": "rating",
            "required": true,
            "label": "Oceń nas:",
            "max": 10,
          },
          {
            "id": 3,
            "type": "text",
            "label": "* - mandatory fields",
          },
          {
            "id": 8,
            "type": "navigation",
            "label": "Send"
          }
        ]
      }
    ]
  }
}

export const jantarGeneral2 = {
  "id": 1,
  "name": 'jantar general',
  "location": 1,
  "schema": {
    "style": {
      "backgroundColor": '#e9f8fd',
      "color": "#015170",
      "accentColor": "#015170",
    },
    "pages": [
      {
        "content": [
          {
            "id": 1,
            "type": "image",
            "image": '/jantar-logo.png'
          },
          {
            "id": 2,
            "type": "text",
            "style": {
              "fontSize": '14px',
            },
            "label": `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Amet nulla facilisi morbi tempus. Congue quisque egestas diam in arcu cursus euismod:\n
            • Integer quis auctor elit sed
            • Integer quis auctor elit sed
            • Integer quis auctor elit sed
            • Integer quis auctor elit sed
            • Integer quis auctor elit sed
            • Integer quis auctor elit sed`
          },
          {
            "id": 4,
            "type": "email",
            "required": false,
            "label": "Twój adres mailowy:",

          },
          {
            "id": 6,
            "type": "comment",
            "required": true,
            "label": "Komentarz:",
          },
          {
            "id": 7,
            "type": "rating",
            "required": true,
            "label": "Oceń nas:",
            "max": 10,
          },
          {
            "id": 3,
            "type": "text",
            "label": "* - mandatory fields",
          },
          {
            "id": 8,
            "type": "navigation",
            "label": "Send"
          }
        ]
      }
    ]
  }
}

export const jantarGeneral3 = {
  "id": 1,
  "name": 'jantar general',
  "location": 1,
  "schema": {
    "style": {
      "backgroundColor": '#b9bbc5',
      "color": "#3a3a3a",
      "accentColor": "#2d5c88",
    },
    "pages": [
      {
        "content": [
          {
            "id": 1,
            "type": "image",
            "image": '/jantar-logo3.png'
          },
          {
            "id": 2,
            "type": "text",
            "style": {
              "fontSize": '14px',
            },
            "label": `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Amet nulla facilisi morbi tempus. Congue quisque egestas diam in arcu cursus euismod:\n
            • Integer quis auctor elit sed
            • Integer quis auctor elit sed
            • Integer quis auctor elit sed
            • Integer quis auctor elit sed
            • Integer quis auctor elit sed
            • Integer quis auctor elit sed`
          },
          {
            "id": 4,
            "type": "email",
            "required": false,
            "label": "Twój adres mailowy:",

          },
          {
            "id": 6,
            "type": "comment",
            "required": true,
            "label": "Komentarz:",
          },
          {
            "id": 7,
            "type": "rating",
            "required": true,
            "label": "Oceń nas:",
            "max": 10,
          },
          {
            "id": 3,
            "type": "text",
            "label": "* - mandatory fields",
          },
          {
            "id": 8,
            "type": "navigation",
            "label": "Send"
          }
        ]
      }
    ]
  }
}

export const jantarGeneral4 = {
  "id": 1,
  "name": 'jantar general',
  "location": 1,
  "schema": {
    "style": {
      "backgroundColor": '#2d4d26',
      "color": "#ffffff",
      "accentColor": "#f0feda",
    },
    "pages": [
      {
        "content": [
          {
            "id": 1,
            "type": "image",
            "image": '/jantar-logo4.jpeg'
          },
          {
            "id": 2,
            "type": "text",
            "style": {
              "fontSize": '14px',
            },
            "label": `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Amet nulla facilisi morbi tempus. Congue quisque egestas diam in arcu cursus euismod:\n
            • Integer quis auctor elit sed
            • Integer quis auctor elit sed
            • Integer quis auctor elit sed
            • Integer quis auctor elit sed
            • Integer quis auctor elit sed
            • Integer quis auctor elit sed`
          },
          {
            "id": 4,
            "type": "email",
            "required": false,
            "label": "Twój adres mailowy:",

          },
          {
            "id": 6,
            "type": "comment",
            "required": true,
            "label": "Komentarz:",
          },
          {
            "id": 7,
            "type": "rating",
            "required": true,
            "label": "Oceń nas:",
            "max": 10,
          },
          {
            "id": 3,
            "type": "text",
            "label": "* - mandatory fields",
          },
          {
            "id": 8,
            "type": "navigation",
            "label": "Send",
            "style": {
              "btnColor": '#f0feda'
            }
          }
        ]
      }
    ]
  }
}

export const jantarGeneral5 = {
  "id": 1,
  "name": 'jantar general',
  "location": 1,
  "schema": {
    "style": {
      "background": 'linear-gradient(4deg, #C7EDFA 1%, #ffffff 69%)',
      "color": "#015170",
      "accentColor": "#015170",
    },
    "pages": [
      {
        "content": [
          {
            "id": 1,
            "type": "image",
            "image": '/jantar-logo5.png',
            "style": {
              "maxHeight": '250px'
            }
          },
          {
            "id": 2,
            "type": "text",
            "style": {
              "fontSize": '13px',
            },
            "label": `Woda Jantar – Kołobrzeski Skarb Natury, to 100% naturalna, podziemna, średniozmineralizowana woda reliktowa o doskonałych właściwościach. Naturalnie czysta o unikatowym składzie mineralnym. Wzbogacona o minerały z soli permskich, które przez miliony lat odkładały się na dnie oceanu. Zawiera elektrolity (sód, potas, wapń, magnez) oraz wiele cennych minerałów, które dobroczynnie wpływają na nasze zdrowie, doskonale nawadnia i gasi pragnienie.`
          },
          {
            "id": 4,
            "type": "email",
            "required": false,
            "label": "Twój adres mailowy:",

          },
          {
            "id": 6,
            "type": "comment",
            "required": true,
            "label": "Komentarz:",
          },
          {
            "id": 7,
            "type": "rating",
            "required": true,
            "label": "Oceń nas:",
            "max": 10,
          },
          {
            "id": 3,
            "type": "text",
            "label": "* - wymagane",
          },
          {
            "id": 8,
            "type": "navigation",
            "label": "Wyślij"
          }
        ]
      }
    ]
  }
}
